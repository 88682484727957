import axios from "axios";
const KEY = "8a6338fa";

export const fetchByID = async (id) => {
  try {
    const res = await axios.get(
      `https://www.omdbapi.com/?apikey=${KEY}&i=${id}`
    );
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchByQuery = async (query, signal) => {
  try {
    const res = await axios.get(
      `https://www.omdbapi.com/?apikey=${KEY}&s=${query}`,
      { signal }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
